@import "core";

@include respond-to(base-inline) {
  .tc_deck__reel{
    .tc_heading__link{position:relative;display:flex;align-items:center;gap:var(--spacing-12);
      &::before{content:"";display:block;min-width:var(--spacing-40-step);min-height:var(--spacing-40-step);border-radius:50%;background-color:var(--color-dark);}
      &::after{content:"";position:absolute;left:0;top:0;min-width:var(--spacing-40-step);min-height:var(--spacing-40-step);}
    }
    .tc_slider__list{gap:var(--spacing-8);}
    .tc_slider__button{bottom:20%;}
  }
  .tc_deck__reel__teaser .tc_teaser__tagline{display:none;}
}

@include respond-to(base) {
  .tc_deck__reel{
    .tc_heading__link::after{mask-size:calc(var(--spacing-16) + var(--spacing-4-step)) auto;background-color:var(--color-light);mask:url("inline!../../../../../icons/play.svg") no-repeat 50% 50%;}
  }
}
